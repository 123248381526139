<template>
  <a-spin :spinning="page_loading">
    <sdPageHeader :title="$t.cargo_records" />
    <Main>
      <a-row
        type="flex"
        justify="center"
        :gutter="16"
        align="top"
      >
        <a-col :span="24">
          <sdCards headless>
            <a-button
              type="primary"
              style="margin-bottom: 10px"
              @click="sendForPrint"
            >
              <template #icon>
                <DownloadOutlined />
              </template>
              {{ $t.batch_printing }}
            </a-button>
            <a-table
              :locale="locale"
              :row-selection="rowSelection"
              :data-source="shipments"
              :columns="columns"
              :pagination="pagination"
              row-key="id"
              @change="handleTableChange"
            >
              <template #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
                <div style="padding: 8px">
                  <a-input
                    ref="searchInput"
                    :placeholder="getPlaceholder(column.dataIndex)"
                    :value="selectedKeys[0]"
                    style="width: 188px; margin-bottom: 8px; display: block"
                    @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                    @pressEnter="confirm"
                  />
                  <a-button
                    type="primary"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="confirm"
                  >
                    <template #icon>
                      <SearchOutlined />
                    </template>
                    {{ $t.searchText }}
                  </a-button>
                  <a-button
                    size="small"
                    style="width: 90px"
                    @click="clearFilters"
                  >
                    {{ $t.searchReset }}
                  </a-button>
                </div>
              </template>
              <template #filterIcon="filtered">
                <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
              </template>
              <template #receiver_cust_name_render="{ record }">
                <span style="text-transform: uppercase">{{ record.receiver_cust_name }}</span>
              </template>
              <template #shipping_company_name_render="{ record }">
                <span style="text-transform: uppercase">{{ record.shipping_company_name }}</span>
              </template>
              <template #created_at_render="{ record }">
                {{ moment(record.created_at).format('DD.MM.YYYY HH:mm') }}
              </template>
              <template #tracking_url_render="{ record }">
                <span v-if="record.tracking_url !== null && record.tracking_url !== ''">
                  <a-button
                    type="primary"
                    shape="circle"
                    @click="goHref(record.tracking_url)"
                  >
                    <template #icon>
                      <sdFeatherIcons type="link" />
                    </template>
                  </a-button>
                </span>
                <span v-else>-</span>
              </template>
              <template #barcode_link_render="{ record }">
                <a-button
                  type="dashed"
                  @click="singlePrint(record.barcode_link)"
                >
                  {{ $t.print }}
                </a-button>
              </template>
              <template #cargo_movements_render="{ record }">
                <a-button
                  type="primary"
                  :loading="page_loading"
                  @click="cargoMovementClick(record.id)"
                >
                  <template #icon>
                    <SearchOutlined />
                  </template>
                  {{ $t.view_transactions }}
                </a-button>
              </template>
            </a-table>
          </sdCards>
        </a-col>
      </a-row>
      <a-drawer
        :title="$t.cargo_movements"
        placement="right"
        width="500"
        :closable="true"
        :visible="show_shipment_status_history"
        @close="show_shipment_status_history = false"
      >
        <a-row>
          <a-col :span="12">
            {{ $t.tstrackingNumber }}
          </a-col>
          <a-col
            :span="12"
          >
            {{ status_histories.length > 0 ? shipment_status_history.order_number : '-' }}
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            {{ $t.trackingNumber }}
          </a-col>
          <a-col
            :span="12"
          >
            {{ status_histories.length > 0 ? shipment_status_history.shipment_number : '-' }}
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            {{ $t.movements }}
          </a-col>
          <a-col :span="12">
            <ul v-if="status_histories.length > 0">
              <li
                v-for="(line, counter) in status_histories"
                :key="line.id"
              >
                {{ $t.state }}: {{ line.message ?? '-' }}
                <br>
                {{ $t.date }}: {{ line.date ?? '-' }}
                <br>
                {{ $t.time }}: {{ line.time ?? '-' }}
                <br>
                <div
                  v-if="status_histories.length !== counter + 1"
                  class="text-center"
                >
                  <DownSquareOutlined />
                </div>
                <br>
              </li>
            </ul>
            <span v-else> - </span>
          </a-col>
        </a-row>
      </a-drawer>
    </Main>
  </a-spin>
</template>

<script>
import { Main } from '../styled'
import { DownloadOutlined, DownSquareOutlined, SearchOutlined } from '@ant-design/icons-vue'
import { defineComponent } from 'vue'
import { prepareTableData } from '@/utility/utility'
import moment from 'moment'

export default defineComponent({
  components: {
    Main,
    SearchOutlined,
    DownloadOutlined,
    DownSquareOutlined,
  },
  data() {
    return {
      shipment_print_url: '',
      shipments: [],
      status_histories: [],
      show_shipment_status_history: false,
      page_loading: true,
      printList: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 30,
        showSizeChanger: true,
        pageSizeOptions: ['10', '30', '50', '100', '200'],
      },
      shipment_status_history: {
        order_number: '',
        shipment_number: '',
      },
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) => {
          this.printList = selectedRows.map(record => record.cargo_key)
        },
        getCheckboxProps: record => ({
          disabled: false,
          name: record.receiver_cust_name,
        }),
      },
      columns: [
        {
          title: this.$t.name,
          dataIndex: 'receiver_cust_name',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'receiver_cust_name_render',
          },
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.$refs.searchInput.focus()
              }, 100)
            }
          },
        },
        {
          title: this.$t.cargo,
          dataIndex: 'shipping_company_name',
          slots: {
            customRender: 'shipping_company_name_render',
          },
        },
        {
          title: this.$t.order_date,
          dataIndex: 'created_at',
          slots: {
            customRender: 'created_at_render',
          },
          sorter: true,
        },
        {
          title: this.$t.trackingNumber,
          dataIndex: 'request_number',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
          },
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.$refs.searchInput.focus()
              }, 100)
            }
          },
        },
        {
          title: this.$t.orderNumber,
          dataIndex: 'cargo_key',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
          },
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.$refs.searchInput.focus()
              }, 100)
            }
          },
        },
        {
          title: this.$t.trackingNo,
          dataIndex: 'tracking_no',
        },
        {
          title: this.$t.trackingUrl,
          dataIndex: 'tracking_url',
          slots: {
            customRender: 'tracking_url_render',
          },
        },
        {
          title: this.$t.shipping_label,
          dataIndex: 'barcode_link',
          slots: {
            customRender: 'barcode_link_render',
          },
        },
        {
          title: this.$t.cargo_movements,
          slots: {
            customRender: 'cargo_movements_render',
          },
        },
      ],
    }
  },
  computed: {
    moment() {
      return moment
    },
    locale() {
      return {
        emptyText: this.$t.searchDontHaveAnyPost,
      }
    },
  },
  mounted() {
    this.loadPageData()
  },
  methods: {
    loadPageData(tableData = undefined) {

      tableData = typeof tableData === 'undefined' ? prepareTableData(this.pagination) : tableData

      this.page_loading = true
      this.$restMethods.postForCargongWebApp('/get-shipment-list', {
        ...tableData,
      }).then(response => {
        if ( ! response.hasError()) {

          const data = response.getData()

          this.shipment_print_url = data.shipment_print_url
          this.shipments = data.shipments
          this.pagination = data.pagination

        }
        this.page_loading = false
      })
    },
    handleTableChange(page, filters, sorter) {
      this.loadPageData(prepareTableData(page, filters, sorter))
    },
    getPlaceholder(dataIndex) {
      const placeholders = {
        receiver_cust_name: this.$t.searchCustomer,
        shipping_company_name: this.$t.searchCargoCompany,
        created_at: this.$t.searchOrderDate,
        request_number: this.$t.searchTrackingNumber,
        cargo_key: this.$t.searchOrderNumber,
        // Diğer sütunlar için benzer şekilde yer tutucuları ekleyebilirsiniz.
      }
      return placeholders[dataIndex] ?? `Search ${dataIndex}`
    },
    goHref(value) {
      window.open(value, '_blank')
    },
    singlePrint(value) {
      window.open(value + ('/' + localStorage.getItem('jwtToken')), '_blank')
    },
    sendForPrint() {
      if (this.printList.length < 1) {
        this.$message.error(this.$t.atLeastOneRecordMustBeSelected)
        return
      }
      window.open(this.shipment_print_url + '/' + this.printList.join(',') + '/' + localStorage.getItem('jwtToken'), '_blank')
    },
    cargoMovementClick(value) {
      this.status_histories = []
      this.$restMethods.postForCargongWebApp('/get-shipment-status-history/' + value).then(response => {

        if ( ! response.hasError()) {

          const data = response.getData()

          this.shipment_status_history.order_number = data.order_number
          this.shipment_status_history.shipment_number = data.shipment_number
          this.status_histories = data.status_histories

          this.show_shipment_status_history = true

        }

      })
    },
  },
})
</script>