/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
export const ellipsis = (text, size) => {
  return `${text
    .split(' ')
    .slice(0, size)
    .join(' ')}...`
}

export const prepareTableData = (page, filters = undefined, sorter = undefined) => {

  return {
    pagination: {
      page: page.current ?? 1,
      take: page.pageSize ?? 100,
    },
    filters: typeof filters === 'undefined' ? [] : filters,
    sort: typeof sorter === 'undefined' ? {
      field: '',
      desc: false,
    } : {
      field: sorter.field,
      desc: sorter.order === 'descend',
    },
  }

}